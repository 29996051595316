import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
position: relative;
background-image: url(${p=>p.img});
width: 100%;
height: 100vh;
background-size: cover;

video{
    width: 100%;
    height: 100%;
    object-fit:cover;
}

@media (max-width: 900px) {
    height:250px;   
    margin-bottom: 00px;
}

`

const Overlay = styled.div`
 width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background: rgba(0, 0, 0, 0.4);

`
const Hero = ({ image }) => {

return(
  
    <Container >
        <a target="_blank"  href="https://www.youtube.com/watch?v=C9pZufFFt5M&ab_channel=GianellyMauricioRosario">
        {/*<video  autoPlay="autoplay" loop muted playsInline>
            <source src="/images/aporta2022noaudio.mp4" type="video/mp4" />
</video>*/}
          <div
          dangerouslySetInnerHTML={{
            __html: `<video  autoplay loop muted playsinline>
      <source src="/images/aporta2022noaudio.mp4" type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
          }}
        />


          </a>
{/*<Overlay></Overlay>*/}
</Container>

  
  
)

}

export default Hero;