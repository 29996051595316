import * as React from "react";
import styled from "styled-components";
import Titlerich from "../Titlerich.js";
import Button from "../Boton.js";

const Container = styled.div`
  width: 100%;
  height: 726px;
  background-color: var(--blanco);
  display: flex;
  //justify-content: center;
  align-items: center;
  padding-top: 78px;
  flex-direction: column;
  position: relative;

  @media (max-width: 900px) {
    height: auto;
    padding-top: 64px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  z-index: 1;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Containercard = styled.div`
  position: relative;
  width: 22%;
  display: flex;
  height: 380px;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Cards = styled.div`
  width: 100%;
  padding: 60px 0px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 30px;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const Color = styled.div`
  position: relative;
  display: flex;
  background-color: ${(p) => p.color};
  color: var(--blanco);
  height: 60%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  border-radius: 4px;

  :hover {
    box-shadow: 0px 0px 40px rgba(${(p) => p.shadow}, 0.6);
    cursor: pointer;
  }

  h2 {
    font-size: 60px;
    z-index: 2;
  }

  h3 {
    font-size: 60px;
    z-index: 2;
  }

  p {
    font-size: 24px;
    margin: 0px;
    z-index: 2;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  //align-items: center;
  height: 40%;
  flex-direction: column;
  position: relative;

  .titulo {
    color: ${(p) => p.colort};
    font-weight: bold;
    margin-bottom: 4px;
    margin-right: 16px;
    margin-top: 8px;
    height: 48px;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .texto {
    font-weight: 400;
    color: var(--gris);
    position: absolute;
    top: 60px;
  }
`;

const Bckimage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
`;
const Lateral = styled.div`
  position: absolute;
  font-size: 120px;
  font-weight: 1000;
  transform: rotate(-90deg);
  -webkit-text-stroke: 2px var(--magenta);
  color: var(--blanco);
  opacity: 0.15;
  bottom: 220px;
  right: -76px;
  z-index: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Card = (props) => {
  return (
    <Containercard>
      <Color color={props.color} shadow={props.sombra}>
        <a
          style={{ position: "absolute", width: "100%", height: "100%" }}
          href={props.link}
        >
          <Bckimage src={props.image} />{" "}
        </a>
        <h2>{props.number}</h2>
        <p>{props.numbertitle}</p>
      </Color>
      <Content colort={props.color}>
        <a style={{ textDecoration: "none" }} href={props.link}>
          <h2 className="titulo">{props.title}</h2>{" "}
        </a>
        <h3 className="texto">{props.text}</h3>
      </Content>
    </Containercard>
  );
};

const Impacto = ({ titulo, boton, card }) => {
 

  return (
    <Container>
      <Titlerich
        container={titulo}
        color="var(--magenta)"
        colortext="#000000"
        colorbold="var(--negro)"
      ></Titlerich>
      <Subcontainer>
        <Cards>
          <Card
            number={card[0].fields.cantidad}
            numbertitle={card[0].fields.titulo}
            color="var(--verde)"
            sombra="var(--verdergb)"
            image={card[0].fields.imagen.fields.file.url}
            text={card[0].fields.descripcionContenido}
            title={card[0].fields.descripcionTitulo}
            link="/proyectos/pais/arcoiris"
          ></Card>

          <Card
            number={card[1].fields.cantidad}
            numbertitle={card[1].fields.titulo}
            color="var(--morado)"
            sombra="var(--moradorgb)"
            image={card[1].fields.imagen.fields.file.url}
            text={card[1].fields.descripcionContenido}
            title={card[1].fields.descripcionTitulo}
            link="/proyectos/pais/volar"
          ></Card>

          <Card
            number={card[2].fields.cantidad}
            numbertitle={card[2].fields.titulo}
            color="var(--naranja)"
            sombra="var(--naranjargb)"
            image={card[2].fields.imagen.fields.file.url}
            text={card[2].fields.descripcionContenido}
            title={card[2].fields.descripcionTitulo}
            link="/proyectos/breca/contencion"
          ></Card>

          <Card
            number={card[3].fields.cantidad}
            numbertitle={card[3].fields.titulo}
            color="var(--celeste)"
            sombra="var(--celestergb)"
            image={card[3].fields.imagen.fields.file.url}
            text={card[3].fields.descripcionContenido}
            title={card[3].fields.descripcionTitulo}
            link="/proyectos/breca/estudiantes"
          ></Card>
        </Cards>
      </Subcontainer>
      <Button enlace="/proyectos">{boton}</Button>
      <Lateral>SOCIAL</Lateral>
    </Container>
  );
};

export default Impacto;
