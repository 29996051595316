import * as React from "react";
import styled from "styled-components";
import Title from "../Title.js";
import Titlerich from "../Titlerich.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Container = styled.div`
  width: 100%;
  height: 866px;
  background-color: var(--morado);
  display: flex;
  //justify-content: center;
  align-items: center;
  padding-top: 78px;
  flex-direction: column;
  position: relative;

  @media (max-width: 900px) {
    padding: 39px 30px 20px 30px;
    height: 800px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: 612px;
  padding: 0px 0px;

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  .react-multi-carousel-dot button {
    border-color: var(--celeste);
    background-color: var(--morado);
  }

  .react-multi-carousel-dot--active {
    button {
      background: var(--celeste);
    }
  }

  .react-multiple-carousel__arrow {
    left: 1394px;
    background: var(--celeste);
    min-width: 36px;
    min-height: 36px;
  }

  .react-multiple-carousel__arrow:hover {
    background: var(--celeste);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(4% + -51px);
  }

  .react-multiple-carousel__arrow--right {
    left: 97%;
  }

  @media (max-width: 900px) {
    width: 100%;
    height: 600px;
  }
`;

const Cardcontainer = styled.div`
  position: relative;
  width: 384px;
  height: 520px;
  background-color: var(--morado);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background-image: url(${(p) => p.imageurl});
  background-size: cover;
  height: 447px;
  background-size: cover;
  border-radius: 4px;
`;

const Description = styled.div`
  position: absolute;
  display: flex;
  bottom: 0px;
  background-color: var(--blanco);
  width: 344px;
  height: 250px;
  padding: 20px 20px 20px 20px;
  text-align: center;
  color: var(--gris);
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;

  h2 {
    position: refont-size: 16px;lative;
    font-weight: 700;
    font-size: 14px;
    color: var(--negro);
    margin-top: 16px;
    width: 90%;
    height: 72px;
    bottom: 10px;
  }

  @media (max-width: 900px) {
    width: 94%;
    height:280px ;
  }

  
  @media (max-width: 375px) {
 font-size:14px
  }
`;

const Lateral = styled.div`
  position: absolute;
  font-size: 120px;
  font-weight: 1000;
  transform: rotate(-90deg);
  -webkit-text-stroke: 2px var(--naranja);
  color: var(--morado);
  opacity: 0.15;
  bottom: 220px;
  left: -50px;
  z-index: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--celeste);
`;

const Arrowcarousel = () => {
  return <Circle></Circle>;
};

const Card = (props) => {
  return (
    <Cardcontainer>
      <Image imageurl={props.imagen}></Image>
      <Description>
        {props.texto}
        <h2>{props.autor}</h2>
      </Description>
    </Cardcontainer>
  );
};

const Exito = ({ titulo, card }) => {

  return (
    <Container>
      <Titlerich
        color="var(--amarillo)"
        container={titulo}
        colortext="var(--blanco)"
        colorbold="var(--blanco)"
      ></Titlerich>
      <Subcontainer>
        <CarouselStyled
          arrows
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          autoPlaySpeed={100000}
          keyBoardControl={true}
          showDots
        >
          {card.map((field, index) => {
            return (
              <Card
                imagen={field.fields.imagen.fields.file.url}
                texto={field.fields.testimonio}
                autor={field.fields.colaborador}
              ></Card>
            );
          })}
        </CarouselStyled>
      </Subcontainer>
      <Lateral>ÉXITO</Lateral>
    </Container>
  );
};

export default Exito;
