import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useContentful from "../utils/useContentful";
import Layoutt from "../components/Layout/Layoutt.js";
import MetaTags from "react-meta-tags";
import Hero from "../components/home/Hero.js";
import Intro from "../components/home/Intro.js";
import Exito from "../components/home/Exito.js";
import Impacto from "../components/home/Impacto.js";
import Noticias from "../components/home/Noticias.js";
import Bottomsection from "../components/proyectos/pais/becatalento/Bottomsection.js";
import "../styles/global.css";
import BoldContentful from "../../src/utils/BoldContentful";

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0px;
`;

const Modal = styled.div`
  position: fixed;
  width: 50vw;
  height: 70vh;
  left: 50vw;
  top: 50vh;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  z-index: 100;
  border-radius: 24px;
  box-shadow: 0px 0px 40px rgba(179, 179, 179, 0.2);
  flex-direction: column;
  display: ${(p) => (p.active ? "flex" : "none")};

  @media (max-width: 900px) {
    width: 94vw;
    height: 96vh;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  svg {
    margin: 12px;
    cursor: pointer;
  }
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h {
    width: 80%;
    font-weight: 700;
    font-size: 24px;
    color: var(--magenta);
    margin-bottom: 16px;
    text-align: center;
  }
  p {
    font-size: 20px;
    text-align: center;
    strong {
      font-weight: 800;
    }
  }

  @media (max-width: 900px) {
    h {
      width: 90%;
    }
  }
`;

const HomeTitle = styled.h1`
  font-weight: 600;
  @media (max-width: 900px) {
    width: 60%;
    text-align: center;
  }
  display: none;
`;

const data = {
  hero: "/images/inicio/hero.png",
  text: "Desafiamos el",
  text2: "STATUS QUO",
  text3: "para",
  hand1: "inspirar",
  hand2: "nuevas realidades",
  content:
    "Somos la plataforma de impacto social de Breca. Trabajamos con las empresas del grupo, individualmente y a nivel transversal, diseñando proyectos de impacto social para convertir las necesidades de nuestros grupos de interés más vulnerables en oportunidades de progreso y desarrollo sostenible.",
  buton1: "Conoce más",
  exitotitulo: "Casos de éxito",
  impactotitulo: "Nuestro impacto social",
  impactoboton: "Ver innovacion social pais",
  noticiastitulo: "Noticias",
  noticiasboton: "ver mas noticias",
  exitocard: [
    {
      texto:
        "El tiempo de cuarentena, me inscribí en el curso de carpintería, he elaborado una puerta, un estante y un cajón. Nunca es tarde para dejar de aprender.",
      negrita: "Colaborador de TASA - Programa de Estudiantes BRECA",
    },
    {
      texto:
        "El tiempo de cuarentena, me inscribí en el curso de carpintería, he elaborado una puerta, un estante y un cajón. Nunca es tarde para dejar de aprender.",
      negrita: "Colaborador de TASA - Programa de Estudiantes BRECA",
    },
    {
      texto:
        "El tiempo de cuarentena, me inscribí en el curso de carpintería, he elaborado una puerta, un estante y un cajón. Nunca es tarde para dejar de aprender.",
      negrita: "Colaborador de TASA - Programa de Estudiantes BRECA",
    },
  ],
  impactocard: [
    {
      texto:
        "Impactamos en la vida de 1700 familias beneficiadas directamente con la intervención del Proyecto Arcoíris.",
      titulo: "Proyecto Arcoíris Cerro San Cristóbal",
      numero: "1700",
    },
    {
      texto:
        "Impactamos en la vida de 1700 familias beneficiadas directamente con la intervención del Proyecto Arcoíris.",
      titulo: "Proyecto Arcoíris Cerro San Cristóbal",
      numero: "1700",
    },
    {
      texto:
        "Impactamos en la vida de 1700 familias beneficiadas directamente con la intervención del Proyecto Arcoíris.",
      titulo: "Proyecto Arcoíris Cerro San Cristóbal",
      numero: "1700",
    },
  ],

  noticiacard: [
    {
      texto:
        "Impactamos en la vida de 1700 familias beneficiadas directamente con la intervención del Proyecto Arcoíris.",
      titulo: "Proyecto Arcoíris Cerro San Cristóbal",

      fecha: "10 Noviembre, 2021",
    },
    {
      texto:
        "Impactamos en la vida de 1700 familias beneficiadas directamente con la intervención del Proyecto Arcoíris.",
      titulo: "Proyecto Arcoíris Cerro San Cristóbal",

      fecha: "10 Noviembre, 2021",
    },
    {
      texto:
        "Impactamos en la vida de 1700 familias beneficiadas directamente con la intervención del Proyecto Arcoíris.",
      titulo: "Proyecto Arcoíris Cerro San Cristóbal",

      fecha: "10 Noviembre, 2021",
    },
  ],
};

const HomeEntryId = "2oDMDgz8LGunEWkUp6HTSA";
const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const IndexPage = () => {
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { data: home, fetched } = useContentful({ id: HomeEntryId });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title> {home.fields.pageTitle}</title>
            <meta name="robots" content="index,follow" />
            
            <meta
              name={home.fields.metaDescription}
              content={home.fields.metaContent}
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/"
            />
             <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/"
            />
            <link rel="canonical" href="https://www.aporta.org.pe/" />
          </MetaTags>

          <Layoutt
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
            {/*  {home.fields.modalActivation ? (
                <>
                  <Modal active={isModalOpen}>
                    <Header>
                      <svg
                        onClick={() => handleCancel()}
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_512_3797)">
                          <path
                            d="M19.4109 18.0002L27.701 9.71019C27.8648 9.51888 27.9504 9.27281 27.9407 9.02113C27.9309 8.76946 27.8266 8.53072 27.6485 8.35262C27.4704 8.17453 27.2317 8.0702 26.98 8.06047C26.7283 8.05075 26.4823 8.13636 26.291 8.30019L18.0009 16.5902L9.71095 8.29019C9.52265 8.10188 9.26725 7.99609 9.00095 7.99609C8.73465 7.99609 8.47925 8.10188 8.29095 8.29019C8.10265 8.47849 7.99686 8.73388 7.99686 9.00019C7.99686 9.26649 8.10265 9.52188 8.29095 9.71019L16.591 18.0002L8.29095 26.2902C8.18627 26.3798 8.10125 26.4902 8.04122 26.6142C7.9812 26.7383 7.94747 26.8734 7.94215 27.0111C7.93683 27.1489 7.96004 27.2862 8.01031 27.4145C8.06059 27.5428 8.13684 27.6594 8.2343 27.7568C8.33175 27.8543 8.4483 27.9305 8.57663 27.9808C8.70495 28.0311 8.84228 28.0543 8.98 28.049C9.11772 28.0437 9.25285 28.0099 9.37692 27.9499C9.50098 27.8899 9.6113 27.8049 9.70095 27.7002L18.0009 19.4102L26.291 27.7002C26.4823 27.864 26.7283 27.9496 26.98 27.9399C27.2317 27.9302 27.4704 27.8258 27.6485 27.6477C27.8266 27.4697 27.9309 27.2309 27.9407 26.9792C27.9504 26.7276 27.8648 26.4815 27.701 26.2902L19.4109 18.0002Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_512_3797">
                            <rect width="36" height="36" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Header>
                    <Body>
                      <h>{home.fields.popUp.fields.modalTitle}</h>

                      <p>
                        {BoldContentful(
                          home.fields.popUp.fields.modalText,
                          "var(--black)"
                        )}
                      </p>
                      <Bottomsection
                        clr={"var(--celeste)"}
                        titulo={home.fields.popUp.fields.buttonsTitle}
                        button={home.fields.popUp.fields.buttonslinks.button}
                        bottomtext={
                          home.fields.popUp.fields.buttonslinks.bottomtext
                        }
                      ></Bottomsection>
                    </Body>
                  </Modal>
                </>
              ) : (
                <></>
              )}*/}
              <Hero image={home.fields.headerImagenDeFondo.fields.file.url} />
              <HomeTitle>Aporta BRECA IMPACTO SOCIAL</HomeTitle>
              <Intro
                texto={data.text}
                textoverde={data.text2}
                texto2={data.text3}
                hand1={data.hand1}
                hand2={data.hand2}
                content={home.fields.conoceMasParrafo}
                boton={home.fields.conoceMasBoton}
              />
              <Exito
                titulo={home.fields.casosDeExito.fields.tituloRich}
                card={home.fields.casosDeExito.fields.cards}
              />
              <Impacto
                titulo={home.fields.nuestroImpactoSocial.fields.tituloRich}
                card={home.fields.nuestroImpactoSocial.fields.cards}
                boton={home.fields.nuestroImpactoSocial.fields.textoBoton}
              />
              <Noticias
                titulo={home.fields.noticias.fields.tituloRich}
                card={home.fields.noticias.fields.cards}
                boton={home.fields.noticias.fields.textoBoton}
              />
            </Container>
          </Layoutt>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
